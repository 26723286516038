import React from "react";
import SearchResultPage from "../../../templates/search-results-template";
import { propertyRedirectionUrl} from "../../../components/utils/utils";
import { navigate } from "gatsby";
const SearchResultResiLettings = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    const urlMappings = propertyRedirectionUrl(fullpath)
    if (urlMappings){
        navigate(urlMappings)
    }
    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/property/for-rent/" 
                pcategorytype="residential" 
                ptype="lettings" 
                ptypetag="for-rent" 
                pstatustype="to let"
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
                psearch="Lettings"
            />
        </React.Fragment>
    );
};

export default SearchResultResiLettings;