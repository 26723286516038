import axios from "axios";
import wordsToNumbers from "words-to-numbers";


// get people name related in review comment
export const getitems = async ({url, setOverallReviews, setTestimonials, teamName, AllPeople}) => {
    // For same name logic
    const NameList = []
    AllPeople.map(data=> {
        let peopleName = data.Name;
        let propsName = teamName;
        if (typeof peopleName === 'string' && typeof propsName === 'string') {
        if(peopleName.includes(propsName.split(' ').slice(0, -1))) {
            NameList.push(peopleName)
        }
        }
    })
    var ggl_reviews = [];
        try {
            const reviewsdata = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
            }
        })// could be from env files
        setOverallReviews(reviewsdata.data)
        if (reviewsdata.data && reviewsdata.data.length > 0) {
            reviewsdata.data.map((item, index) => {
            var star_no = wordsToNumbers(item.starRating);
            if (star_no > 3) {
                let strTitle = item.comment;
                let peopleName = teamName;
                let Name, peopleFirstName;
                let firstName = peopleName.split(' ').slice(0, -1)
                let lastName = peopleName.split(' ').slice(-1) 
                if (typeof peopleName === 'string') {
                    peopleFirstName = NameList.length > 1 ? peopleName : firstName[0] === "" ? peopleName : firstName
                
                }
                if (typeof strTitle === 'string') {
                    Name = strTitle.includes(NameList.length > 1 ? peopleFirstName : firstName[0] === "" ?  peopleFirstName : peopleFirstName[0] )
      
                    if(peopleFirstName == "Jake Cockroft" && (strTitle.includes("Jake") ||  strTitle.includes("Cockroft")) && !strTitle.includes("Jake Parsons")){ 
                          ggl_reviews.push(item);
                          Name = false; 
                    }else if(teamName == "Will Zingkhai"){ 
                        Name = false;
                        if(strTitle.includes(teamName)){
                            ggl_reviews.push(item); 
                        } 
                    } 

                   
                }
                if (Name == true) {
                 ggl_reviews.push(item);
                }  
                
            }
            })
        } 
            setTestimonials(ggl_reviews)
        } catch (error) {
        // cache it if fail/error;
            console.error(error)
        }
}

export const propertyRedirectionUrl =  (fullpath) => {
    const urlMappings = {
        '/property/for-rent/1-bedroom-apartment-dubai': '/property/for-rent/in-dubai/type-apartment/from-1-bedrooms-to-1-bedrooms/',
        '/property/for-rent/2-bedroom-apartment-dubai': '/property/for-rent/in-dubai/type-apartment/from-2-bedrooms-to-2-bedrooms/',
        '/property/for-rent/3-bedroom-apartment-dubai': '/property/for-rent/in-dubai/type-apartment/from-3-bedrooms-to-3-bedrooms/',

        // '/property/for-rent/1-bedroom-townhouse-dubai': '/property/for-rent/in-dubai/type-townhouse/from-1-bedrooms-to-1-bedrooms/',
        // '/property/for-rent/2-bedroom-townhouse-dubai': '/property/for-rent/in-dubai/type-townhouse/from-2-bedrooms-to-2-bedrooms/',
        // '/property/for-rent/3-bedroom-townhouse-dubai': '/property/for-rent/in-dubai/type-townhouse/from-3-bedrooms-to-3-bedrooms/',
		
		
		//  '/property/for-rent/1-bedroom-villa-dubai': '/property/for-rent/in-dubai/type-villa/from-1-bedrooms-to-1-bedrooms/',
        // '/property/for-rent/2-bedroom-villa-dubai': '/property/for-rent/in-dubai/type-villa/from-2-bedrooms-to-2-bedrooms/',
        // '/property/for-rent/3-bedroom-villa-dubai': '/property/for-rent/in-dubai/type-villa/from-3-bedrooms-to-3-bedrooms/',
		
		//  '/property/for-rent/1-bedroom-land-dubai': '/property/for-rent/in-dubai/type-land/from-1-bedrooms-to-1-bedrooms/',
        // '/property/for-rent/2-bedroom-land-dubai': '/property/for-rent/in-dubai/type-land/from-2-bedrooms-to-2-bedrooms/',
        // '/property/for-rent/3-bedroom-land-dubai': '/property/for-rent/in-dubai/type-land/from-3-bedrooms-to-3-bedrooms/',
		
		// '/property/for-rent/1-bedroom-whole-building-dubai': '/property/for-rent/in-dubai/type-whole-building/from-1-bedrooms-to-1-bedrooms/',
        // '/property/for-rent/2-bedroom-whole-building-dubai': '/property/for-rent/in-dubai/type-whole-building/from-2-bedrooms-to-2-bedrooms/',
        // '/property/for-rent/3-bedroom-whole-building-dubai': '/property/for-rent/in-dubai/type-whole-building/from-3-bedrooms-to-3-bedrooms/',
		
		//  '/property/for-rent/1-bedroom-penthouse-dubai': '/property/for-rent/in-dubai/type-penthouse/from-1-bedrooms-to-1-bedrooms/',
        // '/property/for-rent/2-bedroom-penthouse-dubai': '/property/for-rent/in-dubai/type-penthouse/from-2-bedrooms-to-2-bedrooms/',
        // '/property/for-rent/3-bedroom-penthouse-dubai': '/property/for-rent/in-dubai/type-penthouse/from-3-bedrooms-to-3-bedrooms/',
		
		// '/property/for-rent/1-bedroom-duplex-dubai': '/property/for-rent/in-dubai/type-duplex/from-1-bedrooms-to-1-bedrooms/',
        // '/property/for-rent/2-bedroom-duplex-dubai': '/property/for-rent/in-dubai/type-duplex/from-2-bedrooms-to-2-bedrooms/',
        // '/property/for-rent/3-bedroom-duplex-dubai': '/property/for-rent/in-dubai/type-duplex/from-3-bedrooms-to-3-bedrooms/',

		
		//  '/property/for-rent/1-bedroom-plot-dubai': '/property/for-rent/in-dubai/type-plot/from-1-bedrooms-to-1-bedrooms/',
        // '/property/for-rent/2-bedroom-plot-dubai': '/property/for-rent/in-dubai/type-plot/from-2-bedrooms-to-2-bedrooms/',
        // '/property/for-rent/3-bedroom-plot-dubai': '/property/for-rent/in-dubai/type-plot/from-3-bedrooms-to-3-bedrooms/',

		//  '/property/for-rent/1-bedroom-retail-dubai': '/property/for-rent/in-dubai/type-retail/from-1-bedrooms-to-1-bedrooms/',
        // '/property/for-rent/2-bedroom-retail-dubai': '/property/for-rent/in-dubai/type-retail/from-2-bedrooms-to-2-bedrooms/',
        // '/property/for-rent/3-bedroom-retail-dubai': '/property/for-rent/in-dubai/type-retail/from-3-bedrooms-to-3-bedrooms/'
    };
    if (urlMappings.hasOwnProperty(fullpath)) {
        return urlMappings[fullpath]; 
    } else {
        return null; 
    }
};
